/**
 * Basic theme
 */

const palette = {
  primary: {
    contrast: 'white',
    main: 'linear-gradient(to right, #4568dc, #b06ab3)',
    feature: '#F56805',
    active: 'rgb(255,246,0)',
    error: 'red',
  },
  secondary: {
    contrast: 'grey',
    main: '#242424',
    dark: '#181818',
    feature: '#242424',
    error: 'pink',
  },
}

const baseSize = 18

const typography = {
  baseFontSize: `${baseSize}px`,
}

const zIndex = {
  header: 10,
  overlay: 15,
  drawer: 20,
  headerText: 25,
}

export default {
  palette,
  typography,
  zIndex,
  size: size => `${size * baseSize}px`,
}
