import React from 'react';
import classNames from 'classnames';


const Row = (props) =>  {
  const {children, rowProperties = {classNames: []}, className = {}} = props; 
  let rowClass = 'row';
  try {
    rowClass = classNames('row', // ...(className || {}), ...(rowProperties.classNames || {} ) 
    {
      max_height: rowProperties.maxHeight, 
      sm_max_height: rowProperties.maxHeight
    });
  } catch (err) {
    console.log(err);
  }
  return (
    <div className={rowClass}>
      {children}
    </div>
  );
  
}


export default Row;