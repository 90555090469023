import React from 'react';
import classNames from 'classnames';
import FormElementWrapper from './FormElementWrapper';

const TextBoxComponent = (props) => {
  const {
    label, title, type = 'text',
    input,
    meta,
  } = props;

  const inputclass = classNames(['form-control',
    {
      error: meta.touched && meta.error,
    },
  ]);

  const inputWrapper = classNames([{
    'has-error': meta.touched && meta.error,
  },
  ]);

  return (
    <FormElementWrapper type="input" {...props}>
      <div className={inputWrapper}>
        <input className={inputclass} type={type} placeholder={label || title} {...input} />
      </div>
    </FormElementWrapper>
  );
};


export default TextBoxComponent;
