/* eslint-disable import/no-cycle */
import React from 'react';
import classNames from 'classnames';
import styled from 'react-emotion';
import ContentfulElement from '.';

const ContentWrapper = styled.div`
  & th {
    display: ${props => (props.tableOptions && props.tableOptions.noHeader ? 'none' : 'block')}
  }

  & tr td:first-child {
    background-color: ${(props) => {
    if (props.tableOptions && props.tableOptions.firstCellBackground === 'primary') {
      return 'rgb(255,139,0)';
    }
    return 'inherit';
  }};
    color: ${(props) => {
    if (props.tableOptions && props.tableOptions.firstCellBackground === 'primary') {
      return 'white';
    }
    return 'inherit';
  }};
  }
  & td {
    padding: 10px;
  }
`;

const TextWidger = (props) => {
  
  const {
    // eslint-disable-next-line no-unused-vars
    id, title, customClassNames, subTitle, featureTitle, subItems = [], style, options = {}, body = '', showReadMode = false, readModeLink = '#', readMoreText = readModeLink,
  } = props;

  const pClass = options && options.body && options.body.classNames || '';
  const wrapperClass = classNames(customClassNames);
  const headerClass = classNames(options && options.title && options.title.classNames,
    {
      'title-uppercased': options && options.title && options.title.uppercase,
      'title-a': options.bar === 'LeftHeader',
    });
  return (
    <div className={wrapperClass}>
      { options.bar === 'Top' ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
      {style !== 'Body Only' ? <h3 className={headerClass}>{title}</h3> : null }
      { options.bar === 'TItle' ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
      <ContentWrapper {...options}>
        <div className={pClass} dangerouslySetInnerHTML={{ __html: body }} />
      </ContentWrapper>
      { options.bar === 'Middle' ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
      {showReadMode
        ? (
          <p className="mb0">
            <a href={readModeLink} className="read-more">
              {readMoreText}
              <i className="icon arrow_carrot-2right" />
            </a>

          </p>
        )
        : null
        }
      {subItems && subItems.map(item => <ContentfulElement key={item.id} {...item} noWrapper />)}
      { options.bar === 'Bottom' ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
    </div>
  );
};


export default TextWidger;
