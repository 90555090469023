import React from 'react';
import ContentItem from '../index';

export default (props) => {
  const { item, asset } = props;
  return (
    <div>
      <img src={asset.size({
        width: 500,
      })}
        alt={asset.title}
      />
    </div>
  );
};
