/* eslint-disable import/no-cycle */
import React from 'react';
import ContentfulElement from '.';

const TrippleHeaderBlock = (props) => {
  const { header1, header2, header3, content = []} = props;
  return (
    <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 text-center">
        <div className="relative">
            <div className="mb60" />
            <h1 className="text-2 mb0">{header1}</h1>
            <h2 className="title-uppercased">{header2}</h2>
            <div className="br-bottom-center mb30" />
            {content.map(item => <ContentfulElement key={item.id} {...item} classNames="text-3 mb0"/> )}
            <div className="text-10">{header3}</div>
        </div>
    </div>
  );

}


export default TrippleHeaderBlock;