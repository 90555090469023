/* eslint-disable import/no-cycle */
import React from 'react';
import Section from '../react-common-elements/Section';
import ContentfulElement from '.';


const PageSection = (props) => {
  const {rows, sectionProperties} = props;
  return (
    <Section {...props} {...sectionProperties}>
      {rows.map(item => <ContentfulElement key={item.id} {...item} /> )}
    </Section>
  );

}


export default PageSection;