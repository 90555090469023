import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styled, {css, cx} from 'react-emotion';

const backgroundColor = (props) => {
  if(props.hasGradient) return null
  else if(props.SectionStyle === "Dark") {
    return props.theme.palette.secondary.dark;
  }
  return "transparent";
}

const color = (props) => {
  if(props.SectionStyle === "Dark") {
    return props.theme.palette.primary.contrast;
  }
  return "inherit";
}

const BGOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width:100%;
  height:100%;
  z-index: 0;
  opacity: ${props => props.opacity};
  background: ${props => props.background};
`

const SectionWrapper = styled.section`
  background-color: ${props => backgroundColor(props)};
  color: ${props => color(props)};
  background-image: url(${props => props.backgroundImage && props.backgroundImage.size({
    width: 2000
  })});
  background-size: cover;
  @keyframes panning {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 100% 100%;
    }
     50% {
      background-position: 0% 100%;
    }
    75% {
      background-position: 100% 0%;
    }
    200% {
      background-position: 0% 0%;
    }
  }
  animation: ${props => props.panning ? 'panning 300s infinite linear' : 'unset'};

  & h3, & h2, & h1 {
    color: ${props => color(props)};
  }
`

class Section extends Component { 
  
  render() {
    const { NoContainer } = this.props;
    const animatedBackground = css`
    `;
    const containerClass = classNames({
      container: !NoContainer,
    });
    const sectionClass = classNames({'section': !this.props.noSectionCss}, this.props.className,  this.props.classNames);

    const bgOpacity = this.props.bgOpacity || 0.6;
    
    const background = this.props.overlayBackground || (this.props.hasGradient ? "#292c31" : "transparent");

    return (
      <SectionWrapper SectionStyle={this.props.SectionStyle} className={sectionClass} {...this.props}>
        {this.props.anchorName 
        ? 
          <a name={`${this.props.anchorName}`} />
        : 
          null 
        }
        <div className={containerClass}>
          { this.props.hasGradient ? <BGOverlay opacity={bgOpacity} background={background} /> : null }
          {this.props.children}
        </div>
      </SectionWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Section);
