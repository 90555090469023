import { ACTION_UPDATE_DATA, ACTION_UPDATING_DATA } from './constants';
import ContentfulLoader from '.';

const newsType = require('../customTypes/news');
const productType = require('../customTypes/product');

export const ON_UPDATE_DATA = data => ({ type: ACTION_UPDATE_DATA, payload: data });

export const updateData = () => (dispatch) => {
  dispatch({ type: ACTION_UPDATING_DATA });
  const contentful = new ContentfulLoader([newsType, productType]);
  return contentful.refreshData().then((data) => {
    dispatch({
      type: ACTION_UPDATE_DATA,
      data: contentful.generateState(data),
    });
  });
};
