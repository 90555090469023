import React from 'react';
import styled from 'react-emotion';
import ContentItem from '../index';

const Paragraph = styled.div`
`;
const H1 = styled.h1`
`;
const H2 = styled.h2`
`;
const H3 = styled.h3`
`;
const H4 = styled.h4`
`;
const H5 = styled.h5`
`;
const H6 = styled.h6`
`;
export default (props) => {
  const { type, content = [] } = props;
  switch (type) {
    case 'richText-heading-1':
      return (
        <H1>
          {content.map(item => (
            <ContentItem key={item.id} {...item} />
          ))}
        </H1>
      );
    case 'richText-heading-2':
      return (
        <H2>
          {content.map(item => (
            <ContentItem key={item.id} {...item} />
          ))}
        </H2>
      );
    case 'richText-heading-3':
      return (
        <H3>
          {content.map(item => (
            <ContentItem key={item.id} {...item} />
          ))}
        </H3>
      );
    case 'richText-heading-4':
      return (
        <H4>
          {content.map(item => (
            <ContentItem key={item.id} {...item} />
          ))}
        </H4>
      );
    case 'richText-heading-5':
      return (
        <H5>
          {content.map(item => (
            <ContentItem key={item.id} {...item} />
          ))}
        </H5>
      );
    case 'richText-heading-6':
      return (
        <H6>
          {content.map(item => (
            <ContentItem key={item.id} {...item} />
          ))}
        </H6>
      );
    case 'richText-paragraph':
    default:
      return (
        <Paragraph>
          {content.map(item => (
            <ContentItem key={item.id} {...item} />
          ))}
        </Paragraph>
      );
  }
};
