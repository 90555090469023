/* eslint-disable no-unused-vars */
import React from 'react';
import classNames from 'classnames';

const HorizontalBar = (props) => {
  const { id, type, style, customClassNames, options = {} } = props;
  const barClassName = classNames(customClassNames, {
    "simple-hr": style === 'simple',
      mb30: style === 'blankSpace' });
  return (
    <div className={barClassName} />
  );
}


export default HorizontalBar;

/*
<div className="col-sm-12 col-md-4 el_max_height">
  <div className="box-services-4 transparent sm-pt0">
      <Row className="col-p0">
          <div className="col-sm-12">
              <div className="br-bottom mb20 hidden-sm hidden-xs" />
              <h3 className="title title-uppercased small color-default">Thorugh and easy to understand</h3>
              <p className="mb0 color-default">Hunter Valley pest and Building Inspection only performs detailed thorough reports that include colour photographs; the reports are in laymen’s terms, so you can easily understand the written reports which is very important.</p>
          </div>
      </Row>
  </div>
</div>
*/