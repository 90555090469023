/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import { withPrefix } from 'gatsby';
import Script from 'react-load-script';

import TopBar from '../TopBar';
import Footer from '../Footer';
import theme from '../../utils/theme';

import { GAPageView } from '../../utils/ga';


const layoutName = "1JiktKjiPZyxSZGAyDUWS8";

class LayoutTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptsLoaded: {},
      ...props.state,
    };

    this.loadedScript = this.loadedScript.bind(this);
    this.isAllLoaded = this.isAllLoaded.bind(this);
  }

  componentDidMount() {
    const { firePageLoadEvent } = this.props;
    firePageLoadEvent();
    // eslint-disable-next-line no-undef
    if(window.runMain) {
      // eslint-disable-next-line no-undef
      window.runMain()
    }
  }
  /* eslint-disable */
  loadScript(src, async, defered) {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');

      script.src = src;
      script.async = async;
      script.defer = defered;

      document.body.appendChild(script);
    }
  }
  /* eslint-enable */


  loadedScript(scriptName) {
    return () => {
      const { scriptsLoaded } = this.state;
      scriptsLoaded[scriptName] = true;
      this.setState({ scriptsLoaded });
      if (scriptName === 'main') {
        const {runAllMain} = this.props;
        // eslint-disable-next-line no-undef
        // global.loadMain(jQuery, runAllMain);
      }
    };
  }

  isAllLoaded() {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded.jqueryui
      && scriptsLoaded.bootstrap
      && scriptsLoaded.jqueryplugincollection;
  }

  isScriptLoaded(script) {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded[script];
  }

  isJQueryLoadedUI() {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded.jqueryui;
  }

  isBootstrapLoaded() {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded.bootstrap;
    }

  isReload() {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded.main;
  }

  render() {
    const { children, content } = this.props;

    const { layouts } = content.data;
    return (
      <ThemeProvider theme={theme}>
        <div>
          <Script
            url={withPrefix('js/vendor/jquery-1.12.4.min.js')}
            onLoad={this.loadedScript('jquery')}
          />
          
          {this.isScriptLoaded('jquery') ? (
            <div>
              <Script
                url={withPrefix('js/vendor/modernizr-2.6.2.min.js')}
                onLoad={this.loadedScript('modernizr')}
              />
            </div>
          ) : null }
          {this.isScriptLoaded('modernizr') ? (
            <div>
              <Script
                url={withPrefix('js/compressed.js')}
                onLoad={this.loadedScript('compressed')}
              />
            </div>
          ) : null }          
          {this.isScriptLoaded('compressed') ? (
            <div>
              <Script
                url={withPrefix('js/selectize.min.js')}
                onLoad={this.loadedScript('selectize')}
              />
            </div>
          ) : null }
          {this.isScriptLoaded('selectize') || this.isReload() ? (
            <Script
              url={withPrefix('js/main.js?version=1.0.1')}
              onLoad={this.loadedScript('main')}
            />
          ) : null }
          <Helmet
            titleTemplate={`%s | ${layouts[layoutName].siteTitle}`}
            defaultTitle={layouts[layoutName].siteTitle}
            meta={[
              { name: 'description', content: layouts[layoutName].siteDescription },
              { name: 'keywords', content: layouts[layoutName].keywords },
            ]}
          >
            <link rel="canonical" href="https://annex.biolu.be" />
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="author" content="IC-Hosting Pty Ltd" />
            <link rel="stylesheet" href="https://use.typekit.net/ffz0nhn.css" />
            <link
              rel="shortcut icon"
              href={layouts[layoutName].logo.size({
                width: 32,
                height: 32,
              })}
            />

            {/* Add to homescreen for Chrome on Android. Details: http://bit.ly/1otSLve */}
            <meta name="mobile-web-app-capable" content="yes" />
            <link
              rel="icon"
              sizes="196x196"
              href={layouts[layoutName].logo.size({
                width: 196,
                height: 196,
              })}
            />

            {/*  Tile icon for Win8 (144x144 + tile color). Details: http://bit.ly/1Uf14P4 */}
            <meta
              name="msapplication-TileImage"
              content={layouts[layoutName].logo.size({
                width: 144,
                height: 144,
              })}
            />
            <meta name="msapplication-TileColor" content="#202429" />

            {/* Add to homescreen for Safari on iOS. Details: http://apple.co/1Uf0FMJ */}
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black" />
            <meta name="apple-mobile-web-app-title" content="rhee tkd" />

            {/* Apple touch icons. Details: http://apple.co/1Uf0FMJ */}
            <link
              rel="apple-touch-icon"
              href={layouts[layoutName].logo.size({
                width: 57,
                height: 57,
              })}
            />
            <link
              rel="apple-touch-icon"
              sizes="76x76"
              href={layouts[layoutName].logo.size({
                width: 76,
                height: 76,
              })}
            />
            <link
              rel="apple-touch-icon"
              sizes="120x120"
              href={layouts[layoutName].logo.size({
                width: 120,
                height: 120,
              })}
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href={layouts[layoutName].logo.size({
                width: 152,
                height: 152,
              })}
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={layouts[layoutName].logo.size({
                width: 180,
                height: 180,
              })}
            />

            <link rel="stylesheet" href="/css/bootstrap.min.css" />
            <link rel="stylesheet" href="/css/animations.css" />
            <link rel="stylesheet" href="/css/fonts.css" />
            <link rel="stylesheet" href="/css/main.css" className="color-switcher-link" />
            <link rel="stylesheet" href="/css/shop.css" className="color-switcher-link" />
            {/* <!-- ============================================= --> */}
          </Helmet>
          <div className="preloader">
            <div className="preloader_image"></div>
          </div>
          <div id="canvas">
            <div id="box_wrapper">
              {/* <TopBar /> */}
              <a name="home" />
              <div className="main-content">
                {children}
              </div>
              <Footer content={content}/>
              <section className="page_copyright cs section_padding_15">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 text-center">
                      <p className="small-text">&copy; Copyright 2020 All Rights Reserved</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

const firePageLoad = dispatch => () => dispatch(GAPageView({ type: 'GA_PAGE_LOAD' }));

const mapStateToProps = state => ({ state, content: state.contentful });


const mapDispatchToProps = dispatch => ({
  firePageLoadEvent: firePageLoad(dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutTemplate);
