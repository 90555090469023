import React, { Component } from 'react';
import styled from 'react-emotion';

const ValidationWarning = styled.div`
`;

const ValidationWarningMessage = styled.div`
  display: ${props => props.isValid || props.isValid.valid ? 'none' : 'block'}
`;

class ValidationWarningComponent extends Component { 

  constructor(props) {
    super(props);
    this.state = { isValid: { valid: true } };
  }  

  render() {
    const {children} = this.props;
    return (
      <ValidationWarning>
        <ValidationWarningMessage isValid={this.state.isValid}>
          {/* this.state.isValid.messages.each((message, i) => 
              <div key={`warning${i}`}>{message}</div>
          ) */}
        </ValidationWarningMessage>  
        {children}
      </ValidationWarning>
    );
  }
}

export default ValidationWarningComponent;