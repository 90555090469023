import React from 'react';
// import styled from 'react-emotion';
import { Link } from 'gatsby';
import ContentItem from '../index';

export default (props) => {
  const { content = [], url } = props;
  return (
    <Link to={url}>
      {content.map(item => (
        <ContentItem key={item.id} {...item} />
      ))}
    </Link>
  );
};
