/* eslint-disable import/no-cycle */
import React from 'react';
import classNames from 'classnames';

const replaceAll = (target, search, replacement) => target.replace(new RegExp(search, 'g'), replacement);

const Element = (props) => {
  const {id, element, classes, content} = props;
  const elementClasses = classNames(classes);
  switch(element) {
    case 'h1':
      return (
        <h1 key={id} className={elementClasses} dangerouslySetInnerHTML={{__html: replaceAll(content, '<strong>', '<strong class="text-theme-colored">')}} />
      );
    case 'h2':
      return (
        <h2 key={id} className={elementClasses} dangerouslySetInnerHTML={{__html: replaceAll(content, '<strong>', '<strong class="text-theme-colored">')}} />
      );
    case 'h3':
      return (
        <h3 key={id} className={elementClasses} dangerouslySetInnerHTML={{__html: replaceAll(content, '<strong>', '<strong class="text-theme-colored">')}} />
      );
    case 'h4':
      return (
        <h4 key={id} className={elementClasses} dangerouslySetInnerHTML={{__html: replaceAll(content, '<strong>', '<strong class="text-theme-colored">')}} />
      );
    case 'h5':
      return (
        <h5 key={id} className={elementClasses} dangerouslySetInnerHTML={{__html: replaceAll(content, '<strong>', '<strong class="text-theme-colored">')}} />
      );
    case 'span':
      return (
        <span key={id} className={elementClasses} dangerouslySetInnerHTML={{__html: replaceAll(content, '<strong>', '<strong class="text-theme-colored">')}} />
      );
    case 'div':
      return (
        <div key={id} className={elementClasses} dangerouslySetInnerHTML={{__html: replaceAll(content, '<strong>', '<strong class="text-theme-colored">')}} />
      );      
    case 'p':
    default:
      return (
        <p key={`${id}${Math.random()}`} className={elementClasses} dangerouslySetInnerHTML={{__html: replaceAll(content, '<strong>', '<strong class="text-theme-colored">')}} />
      );
  }
  
}

const TextWidger = (props) => {
  
  const {
    // eslint-disable-next-line no-unused-vars
    id, title, body, showTitle, titleElement, titleClasses = [], showBody, bodyElement, bodyClasses,  options = {}
  } = props;

  if(showBody && showTitle) {
    return (
      <div>
        {showTitle ? <Element id={id} element={titleElement} classes={titleClasses} content={title} /> : null }
        {showBody ? <Element id={id} element={bodyElement} classes={bodyClasses} content={body} /> : null }
      </div>
    );
  }
  if (showTitle) {
    return (
      <Element id={id} element={titleElement} classes={titleClasses} content={title} />
    );
  }
  return (
    <Element id={id} element={bodyElement} classes={bodyClasses} content={body} />
  );
};


export default TextWidger;
