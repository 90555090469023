/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

import classNames from 'classnames';


class ImageWidget extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      width: 100
    }
  }

  componentDidMount() { 
    this.boundingBox = this.imgElement.getBoundingClientRect();

    this.setState({ 
        width:this.boundingBox.width.toFixed(0)
    }); 
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const {id, title, caption, featureTitle, images = [], effects=[], customClassNames, options } = this.props;
    const radius = e => {
      if(e.includes('round-med')) return 10;
      return 0;
    }
   
    const imageOptions = {
      radius: radius(effects),
      ...options.imageOptions
    };
    
    const wrapperClass = classNames(customClassNames);
    return (
      <div ref={element => this.imgElement = element } className={wrapperClass} >
        {images.length === 1 ? 
          <img src={images[0].size({ width: this.state.width, ...imageOptions})} alt={images[0].title} />
        : <p>have not implemented multiple images yet</p>}
      </div>
    );

  }
}

export default ImageWidget;
