/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import classNames from 'classnames';

import {Link} from 'gatsby';
import ContentfulElement from ".";

const TYPE_ICON_BOX = "iconBox"

const SHOW_TITLE = 'showTitle';
const ICON_LEFT = 'iconLeft';
const UNDECORATED_TITLE = 'undecoratedTitle';
const TITLE_MAIL_TO_LINK = 'mailToLinkTitle';
const TITLE_TEL_LINK = 'telLinkTitle';
// const FLATICON = 'flaticon';

class GenericElement extends Component { 

  constructor(props) {
    super(props);
    this.hasMode = this.hasMode.bind(this)
  }

  hasMode(mode) {
    const modes = this.props.modes || [];
    return modes.indexOf(mode) > -1;
  }

  render() {
    const {id, type, elementType, title, mainText, cssClassNames, modes = [], options = {}, subItems, icon = '', linkUrl } = this.props;

    const pClass = options && options.body && options.body.classNames || '';
    const wrapperClass = classNames(cssClassNames);
    const headerClass = classNames(options && options.title && options.title.classNames,
    {
      "title-uppercased": options && options.title && options.title.uppercase,
      "title-a": options.bar === "LeftHeader",
    });

    const iconCLass = classNames('fa', `fa-${icon}`);
    const flatIconiconCLass = classNames(`flaticon-${icon}`);
    
    if(elementType === TYPE_ICON_BOX) {
      return (
        <div className={wrapperClass}>
        <div className="media">
          <div className="pull-left">
            <Link to={linkUrl}>
              <span className={flatIconiconCLass} />
            </Link>
          </div>
          <div className="media-body">
            <h4 className="media-heading"><Link to={linkUrl}>{title}</Link></h4>
            <p dangerouslySetInnerHTML={{ __html: mainText }} />
            <div className="read-more">
              <Link to={linkUrl}>Read More  <i className="fa fa-angle-right" aria-hidden="true" /></Link>
            </div>
          </div>
        </div>
      </div>
      )
    }
    
    /* Default */
    return (
      <div className={wrapperClass} >
        {this.hasMode(ICON_LEFT) ?  <i className={iconCLass} aria-hidden="true" /> : null }
                  { options.bar === "Top" ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
        {this.hasMode(UNDECORATED_TITLE) ? <span>{title}</span> : 
          this.hasMode(TITLE_MAIL_TO_LINK) ? <a href={`mailto://${title}`}>{title}</a> : 
          this.hasMode(TITLE_TEL_LINK) ?  <a href={`tel://${title}`}>{title}</a> : 
          this.hasMode(SHOW_TITLE) ?  <h3 className={headerClass}>{title}</h3> : null
        }
        { options.bar === "TItle" ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
                
        {mainText ? <div className={pClass} dangerouslySetInnerHTML={{ __html: mainText }} /> : null}
        { options.bar === "Middle" ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
        
        {subItems && subItems.map(item => <ContentfulElement key={item.id} {...item} noWrapper /> )}
        { options.bar === "Bottom" ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
      </div>
    );

  }
}

export default GenericElement;