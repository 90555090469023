/* eslint-disable no-unused-vars */
import React from 'react';
import {Link} from 'gatsby';
import classNames from 'classnames';

const ButtonLink = (props) => {
  const { id, type, style, buttonText, subText, buttonLink, customClassNames, options = {} } = props;
  const wrapperClass = classNames(customClassNames);
  if(style === "Primary CTA") {
    return (
      <div className={wrapperClass}>
        <div className="mb60" />
        <Link to={buttonLink} className="btn-bg btn-3d btn-subtext">
            <span>{buttonText}</span>
            <small>{subText}</small>
        </Link>
      </div>
    );
  }
  return ( <p>Unknown Style: {style}</p> );
}

export default ButtonLink;

/*
<div className="col-sm-12 col-md-4 el_max_height">
  <div className="box-services-4 transparent sm-pt0">
      <Row className="col-p0">
          <div className="col-sm-12">
              <div className="br-bottom mb20 hidden-sm hidden-xs" />
              <h3 className="title title-uppercased small color-default">Thorugh and easy to understand</h3>
              <p className="mb0 color-default">Hunter Valley pest and Building Inspection only performs detailed thorough reports that include colour photographs; the reports are in laymen’s terms, so you can easily understand the written reports which is very important.</p>
          </div>
      </Row>
  </div>
</div>
*/