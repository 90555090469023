/* eslint-disable import/no-cycle */
import React from 'react';
import ClassNames from 'classnames';
import Row from '../react-common-elements/Row';

import ContentfulElement from '.';

const SectionRow = (props) => {
  const {entities, rowProperties={}} = props;
  return (
    <Row {...props}>
      {entities && entities.map(item => 
        rowProperties.renderColumns ? 
          <div key={item.id} className={ClassNames(rowProperties.colClassNames)}>
            <ContentfulElement key={item.id} {...item} maxHeight={rowProperties.maxHeight} />
          </div>
        : <ContentfulElement key={item.id} {...item} maxHeight={rowProperties.maxHeight} />
      )}
    </Row>
  );

}


export default SectionRow;