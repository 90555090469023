/* eslint-disable import/no-cycle */
import React from 'react';
import classNames from 'classnames';
import ContentfulElement from ".";


const SimpleCollectionOfEntities = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { id, type, mode, customClassNames, maxHeight, entities = [], seperatorClass = "", options = {} } = props;
  const wrapperClass = classNames(options.classNames, { el_max_height: maxHeight});
  return (
    <div className={wrapperClass}>
      {entities && entities.map(item => (
        <div key={item.id}>
          <ContentfulElement {...item} /> 
          <div className={seperatorClass} />
        </div>
      ))}
    </div>
  );
}


export default SimpleCollectionOfEntities;
