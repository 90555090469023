import React, { Component } from 'react';
import {Link} from 'gatsby';
import { TypeEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

class ProductWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: []
    }
  }

  componentDidMount() {
  }

  render() {
    const { product } = this.props;
    return (
    <div className="col-md-4 center">
        <TypeEditor type="product" space={product.space}>
        <div className="owl-stage-outer">
            <div className="owl-stage" style={{transform: "translate3d(0px, 0px, 0px)", transition: "all 0s ease 0s; width: 2460px"}}>
                <div className="owl-item active" style={{width: "350px", marginRight: "60px"}}>
                    <article className="vertical-item content-padding big-padding ds ms with_shadow text-center">
                        <div className="item-media"> <img src={product.featureImage.size({ width: 350 })} alt="" /> </div>
                        <div className="item-content">
                            <header className="entry-header"> <span className="small-text highlight">{product.title.split(" ")[0]}</span>
                                <h3 className="entry-title text-uppercase"> <Link to={`/product/${product.slug}`}>{product.title.split(" ")[1]}</Link> </h3>
                                <span className="small-text highlight">{product.subTitle}</span>
                                <span className="entry-title text-uppercase">{product.categoryTitle}</span>
                            </header>
                            <p>{product.shortDescription}</p>
                            <p className="topmargin_35"> <Link to={`/product/${product.slug}`} className="theme_button color1 block_button">Read more</Link> </p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
        </TypeEditor>
    </div>
    );
  }
};

export default ProductWidget;