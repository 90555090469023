import React from 'react';
import styled from 'react-emotion';
import ContentItem from '../index';

const OrderedList = styled.ol`
`;

export default (props) => {
  const { content = [] } = props;
  return (
    <OrderedList>
      {content.map(item => (
        <ContentItem key={item.id} {...item} />
      ))}
    </OrderedList>
  );
};
