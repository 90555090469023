/* export const anything = () => { return {message: '', valid: true }};
export const nothing = (value, control) => { return {message: `Nothing is ever valid for ${control}`, valid: true }}; */

export const required = (value, x, y, z, a) =>{ 
  if(!value) {
    return `${z} is required`;
  }
  return undefined;
}

export const validate = (control, validators, value) =>  validators.reduce((a,b) => {
  const isValid = b(value, control);
  return {
    messages: a.messages.concat(isValid.message).filter(x => x !== ''),
    valid: a && isValid.valid}
  },
  {messages: [], valid: true});


export const postCode = value => { 
  //if(value.length !== 4) return "must be 4 digits";
  if(isNaN(value)) return "must be a number";
}

export const email = value => { 
  const re = /\S+@\S+\.\S+/;
  if(!re.test(value)) return "Must be a valid email address"
}