import React from 'react';
import ContentItem from '../index';

export default (props) => {
  const { item } = props;
  return (
    <div>
      <ContentItem {...item} />
    </div>
  );
};
