/* eslint-disable react/self-closing-comp */
import React from 'react';

import ContactForm from '../ContactForm';
import CMSWrapper, { ImageEditor } from '@ic-hosting/ich-contentful-cms-wrapper';

import InfrastructureConfig from '../../data/infrastructure'

const Footer = (props) => {
  const { content, bgImage } = props;
  const layoutName = "1JiktKjiPZyxSZGAyDUWS8";
  const { layouts } = content.data;
  
	const layout = layouts[layoutName];
	
	const infra = new InfrastructureConfig().getOutputs()

  return (
		<>
		<section id="contact" className="cs section_padding_top_150 section_padding_bottom_150 columns_padding_30">
				<div className="container">
						<h2 className="section_header big text-uppercase top-offset"><span className="highlight">Contact Us</span><br/>&nbsp;</h2>
						<ContactForm  header={null} anchor="contact" recaptchaSiteKey={infra.recaptchaSiteKey} contactFormApiUrl={infra.contact_form_api_url} />
				</div>
		</section>
    <footer className="page_footer ds dark section_padding_top_150 section_padding_bottom_140 columns_margin_bottom_40 columns_padding_30">
      <CMSWrapper {...layout}>
      <ImageEditor {...bgImage}>
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-md-3">
							<div className="widget widget_about">
								<div className="logo">
								<img src={layout.logo.size({
                	width: 350,
                	format: 'png'
              	})} alt="" />> </div>
							</div>
						</div>
						<div className="col-xs-12 col-md-3">
							<div className="widget widget_text">
							</div>
						</div>
						<div className="col-xs-12 col-md-3">
							<div className="widget widget_text">
							</div>
						</div>
						<div className="col-xs-12 col-md-3">
							<div className="widget widget_text">
								<h3 className="widget-title">Get in touch</h3>
								<ul className="list-unstyled darklinks">
									<li>
										<div className="media small-media">
											<div className="media-left"> <i className="fa fa-map-marker highlight cons-width"></i> </div>
											<div className="media-body">PO Box 77<br/>Charlestown NSW 2290</div>
										</div>
									</li>
									<li>
										<div className="media small-media">
											<div className="media-left"> <i className="fa fa-phone highlight cons-width"></i> </div>
											<div className="media-body">02 4047 1805</div>
										</div>
									</li>
									<li>
										<div className="media small-media">
											<div className="media-left"> <i className="fa fa-pencil highlight cons-width"></i> </div>
											<div className="media-body"> <a href="mailto:annex@biolu.be">annex@biolu.be</a> </div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</ImageEditor>
      </CMSWrapper>
			</footer>
		</>
  );
};


export default Footer;
