import React from 'react';
import styled from 'react-emotion';
import FormElementWrapper from './FormElementWrapper';
import classNames from 'classnames';
import MessageTooltip from './FormToolTip';
import BaseComponent from './BaseComponent';

const StyledTextArea = styled.textarea`
  width: 100%
`;

class TextAreaComponent extends BaseComponent {
  render() {
    const {
      meta,
    } = this.props;
    const inputclass = classNames(['form-control',
      {
        error: meta.touched && meta.error,
      },
    ]);

    const inputWrapper = classNames([{
      'has-error': meta.touched && meta.error,
    },
    ]);
    return (

      <FormElementWrapper type="textarea" {...this.props}>
        <div className={inputWrapper}>
          <textarea className={inputclass} {...this.props} {...this.props.input} placeholder={this.props.label} />
        </div>
      </FormElementWrapper>
    );
  }
}

export default TextAreaComponent;
