import React from 'react';
import styled from 'react-emotion';

const Formating = styled.span`
  font-weight: ${props => ((props.options && props.options.includes('bold')) ? 'bold' : 'inherit')};
  font-style: ${props => ((props.options && props.options.includes('italic')) ? 'italic' : 'inherit')};
  text-decoration: ${props => ((props.options && props.options.includes('underline')) ? 'underline' : 'inherit')};
`;

export default (props) => {
  const { marks, content } = props;
  return (
    <Formating options={marks} dangerouslySetInnerHTML={{ __html: content }} />
  );
};
