import React from 'react';

export default (props) => {
  const {masked} = props;
  if(masked){
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">
        <defs>
          <mask id="slideClipPath1">
            <rect width="100%" height="100%" fill="white"/>
            <g transform="rotate(270, 450, 450)"> 
              <path d="M247.19,282.2H147.42q4.36-18.91,21.66-18.49l1.83.28a110.49,110.49,0,0,1,14.48,5.34q15.54,6.54,13.71-5.84l-8.65-33.12L139.19,282.2h-43l68.41-69.4q18.28-18.14,45.84-17h14.13Z" transform="translate(-95.35 -195.72)"/>
              <path d="M370,202.39a4.8,4.8,0,0,1-.42,2l-28.2,77.77H302.48l24.61-67.71H296.86L272.25,282.2H233.51l31.22-86.41h95.06a13.79,13.79,0,0,1,6.47,1.62Q370,199.23,370,202.39Z" transform="translate(-95.35 -195.72)"/>
              <path d="M374,202.39a4.8,4.8,0,0,1-.42,2l-28.2,77.77H306.5l24.61-67.71H300.88L276.27,282.2H237.52l31.22-86.41H363.8a13.79,13.79,0,0,1,6.47,1.62Q374,199.23,374,202.39Z" transform="translate(-95.35 -195.72)"/>
              <path d="M486.3,202.39a4.8,4.8,0,0,1-.42,2l-28.2,77.77H418.8l24.61-67.71H413.17L388.56,282.2H349.82L381,195.79H476.1a13.79,13.79,0,0,1,6.47,1.62Q486.3,199.23,486.3,202.39Z" transform="translate(-95.35 -195.72)"/>
              <path d="M601.34,195.79l-19.69,18.7H525.47l-6.33,17.3h56.18l-5.2,14.41H513.94l-6.33,17.3,81-.07L564.14,282.2h-102l31.22-86.41Z" transform="translate(-95.35 -195.72)" />
              <path d="M731.29,195.79,674.55,239l26.86,45.91q-42.54,0-59.7-20.88L617.8,282.2H570.41L627,239l-25.38-43.24h47.53l10.69,18.14,23.91-18.14Z" transform="translate(-95.35 -195.72)" />
              <path d="M133.16,331.44H96V292.18h35.36q6.59,0,9.83,2.72t3.24,8.25A8.8,8.8,0,0,1,143,308.3a9.11,9.11,0,0,1-4.39,3.15,8.13,8.13,0,0,1,5.43,2.86q1.71,2.25,1.71,6.5,0,5.56-3,8.09T133.16,331.44Zm-4.17-24a3,3,0,0,0,2.29-.83,3.36,3.36,0,0,0,.79-2.41,3.26,3.26,0,0,0-.86-2.35A2.94,2.94,0,0,0,129,301H107.93v6.49Zm1.08,15.06a3.14,3.14,0,0,0,2.44-.94,3.83,3.83,0,0,0,.86-2.68,3.49,3.49,0,0,0-.9-2.52,3.17,3.17,0,0,0-2.4-.94H107.93v7.07Z" transform="translate(-95.35 -195.72)"/>
              <path d="M209.87,331.44h-12V292.18h12Z" transform="translate(-95.35 -195.72)"/>
              <path d="M281.68,331.91a58.49,58.49,0,0,1-10.07-.62,12.53,12.53,0,0,1-5.19-2.12,9.43,9.43,0,0,1-3.27-4.42,21.48,21.48,0,0,1-1-7.24V306.81a24.44,24.44,0,0,1,1-7.8,9.37,9.37,0,0,1,3.27-4.56,12.74,12.74,0,0,1,5.26-2.11,57.23,57.23,0,0,1,10-.63h14.11a57.92,57.92,0,0,1,10,.63,12.55,12.55,0,0,1,5.26,2.11,9.5,9.5,0,0,1,3.2,4.54,22.45,22.45,0,0,1,1,7.36v10.94a22.29,22.29,0,0,1-1,7.37,9.67,9.67,0,0,1-3.2,4.52,12.36,12.36,0,0,1-5.22,2.12,58.84,58.84,0,0,1-10,.62Zm3.24-9.81h7.62q6.35,0,8.44-1.6t2.08-5.92V309q0-4.32-2.08-5.92t-8.44-1.59h-7.67q-6.49,0-8.5,1.61t-2,6.12v5.46q0,4.25,2.12,5.83T284.93,322.1Z" transform="translate(-95.35 -195.72)"/>
              <path d="M379.87,321.95H406v9.49H367.87V292.18h12Z" transform="translate(-95.35 -195.72)"/>
              <path d="M455.32,292.18h12v23.28q0,4,1.81,5.34t7.87,1.36h6.8q5,0,6.75-1.44t1.71-5.26V292.18h12v27q0,6.93-3.73,9.83t-12.91,2.9H474.2q-10.78,0-14.83-2.77t-4-10Z" transform="translate(-95.35 -195.72)"/>
              <path d="M593.87,331.44h-37.2V292.18H592q6.59,0,9.83,2.72t3.24,8.25a8.8,8.8,0,0,1-1.41,5.16,9.11,9.11,0,0,1-4.39,3.15,8.12,8.12,0,0,1,5.43,2.86q1.71,2.25,1.71,6.5,0,5.56-3,8.09T593.87,331.44Zm-4.17-24a3,3,0,0,0,2.29-.83,3.36,3.36,0,0,0,.79-2.41,3.26,3.26,0,0,0-.86-2.35,2.94,2.94,0,0,0-2.23-.9H568.64v6.49Zm1.08,15.06a3.14,3.14,0,0,0,2.44-.94,3.83,3.83,0,0,0,.86-2.68,3.49,3.49,0,0,0-.9-2.52,3.17,3.17,0,0,0-2.4-.94H568.64v7.07Z" transform="translate(-95.35 -195.72)"/>
              <path d="M701,301.75H670v5.93h29.13v8.25H670v6h31.4v9.49H658.07V292.18H701Z" transform="translate(-95.35 -195.72)"/>
            </g>
            {/* <text x="-1.2%" y="50%" className="mask-text first">Annnex</text>
            <text x="-1.2%" y="50%" className="mask-text second">biolube</text>
  <text x="-1.2%" y="50%" className="mask-text third"></text> */}
          </mask>
        </defs>
        <rect className="mask" width="100%" height="100%" mask="url(#slideClipPath1)" />
      </svg>
    )
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="960" height="1000">
        <defs>
          <mask id="slideClipPath1">
            <rect width="100%" height="100%" fill="white"/>
            <g transform="rotate(270, 500, 500)"> 
              <path d="M247.19,282.2H147.42q4.36-18.91,21.66-18.49l1.83.28a110.49,110.49,0,0,1,14.48,5.34q15.54,6.54,13.71-5.84l-8.65-33.12L139.19,282.2h-43l68.41-69.4q18.28-18.14,45.84-17h14.13Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M370,202.39a4.8,4.8,0,0,1-.42,2l-28.2,77.77H302.48l24.61-67.71H296.86L272.25,282.2H233.51l31.22-86.41h95.06a13.79,13.79,0,0,1,6.47,1.62Q370,199.23,370,202.39Z" transform="translate(-95.35 -195.72)" fill="#fff"/>
              <path d="M374,202.39a4.8,4.8,0,0,1-.42,2l-28.2,77.77H306.5l24.61-67.71H300.88L276.27,282.2H237.52l31.22-86.41H363.8a13.79,13.79,0,0,1,6.47,1.62Q374,199.23,374,202.39Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M486.3,202.39a4.8,4.8,0,0,1-.42,2l-28.2,77.77H418.8l24.61-67.71H413.17L388.56,282.2H349.82L381,195.79H476.1a13.79,13.79,0,0,1,6.47,1.62Q486.3,199.23,486.3,202.39Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M601.34,195.79l-19.69,18.7H525.47l-6.33,17.3h56.18l-5.2,14.41H513.94l-6.33,17.3,81-.07L564.14,282.2h-102l31.22-86.41Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M731.29,195.79,674.55,239l26.86,45.91q-42.54,0-59.7-20.88L617.8,282.2H570.41L627,239l-25.38-43.24h47.53l10.69,18.14,23.91-18.14Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M133.16,331.44H96V292.18h35.36q6.59,0,9.83,2.72t3.24,8.25A8.8,8.8,0,0,1,143,308.3a9.11,9.11,0,0,1-4.39,3.15,8.13,8.13,0,0,1,5.43,2.86q1.71,2.25,1.71,6.5,0,5.56-3,8.09T133.16,331.44Zm-4.17-24a3,3,0,0,0,2.29-.83,3.36,3.36,0,0,0,.79-2.41,3.26,3.26,0,0,0-.86-2.35A2.94,2.94,0,0,0,129,301H107.93v6.49Zm1.08,15.06a3.14,3.14,0,0,0,2.44-.94,3.83,3.83,0,0,0,.86-2.68,3.49,3.49,0,0,0-.9-2.52,3.17,3.17,0,0,0-2.4-.94H107.93v7.07Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M209.87,331.44h-12V292.18h12Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M281.68,331.91a58.49,58.49,0,0,1-10.07-.62,12.53,12.53,0,0,1-5.19-2.12,9.43,9.43,0,0,1-3.27-4.42,21.48,21.48,0,0,1-1-7.24V306.81a24.44,24.44,0,0,1,1-7.8,9.37,9.37,0,0,1,3.27-4.56,12.74,12.74,0,0,1,5.26-2.11,57.23,57.23,0,0,1,10-.63h14.11a57.92,57.92,0,0,1,10,.63,12.55,12.55,0,0,1,5.26,2.11,9.5,9.5,0,0,1,3.2,4.54,22.45,22.45,0,0,1,1,7.36v10.94a22.29,22.29,0,0,1-1,7.37,9.67,9.67,0,0,1-3.2,4.52,12.36,12.36,0,0,1-5.22,2.12,58.84,58.84,0,0,1-10,.62Zm3.24-9.81h7.62q6.35,0,8.44-1.6t2.08-5.92V309q0-4.32-2.08-5.92t-8.44-1.59h-7.67q-6.49,0-8.5,1.61t-2,6.12v5.46q0,4.25,2.12,5.83T284.93,322.1Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M379.87,321.95H406v9.49H367.87V292.18h12Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M455.32,292.18h12v23.28q0,4,1.81,5.34t7.87,1.36h6.8q5,0,6.75-1.44t1.71-5.26V292.18h12v27q0,6.93-3.73,9.83t-12.91,2.9H474.2q-10.78,0-14.83-2.77t-4-10Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M593.87,331.44h-37.2V292.18H592q6.59,0,9.83,2.72t3.24,8.25a8.8,8.8,0,0,1-1.41,5.16,9.11,9.11,0,0,1-4.39,3.15,8.12,8.12,0,0,1,5.43,2.86q1.71,2.25,1.71,6.5,0,5.56-3,8.09T593.87,331.44Zm-4.17-24a3,3,0,0,0,2.29-.83,3.36,3.36,0,0,0,.79-2.41,3.26,3.26,0,0,0-.86-2.35,2.94,2.94,0,0,0-2.23-.9H568.64v6.49Zm1.08,15.06a3.14,3.14,0,0,0,2.44-.94,3.83,3.83,0,0,0,.86-2.68,3.49,3.49,0,0,0-.9-2.52,3.17,3.17,0,0,0-2.4-.94H568.64v7.07Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
              <path d="M701,301.75H670v5.93h29.13v8.25H670v6h31.4v9.49H658.07V292.18H701Z" transform="translate(-95.35 -195.72)" fill="#231f20"/>
            </g>
          {/* <text x="-1.2%" y="50%" className="mask-text first">Annnex</text>
          <text x="-1.2%" y="50%" className="mask-text second">biolube</text>
      <text x="-1.2%" y="50%" className="mask-text third"></text> */}
          </mask>
        </defs>
        <rect className="mask" width="100%" height="100%" mask="url(#slideClipPath1)" />
      </svg>
    )
  }
}