import React from 'react';
/* eslint-disable */
import ContentfulElement from '.';

const partitionArray = (array, size) => array.map((e, i) => 
  ((i % size === 0) ? array.slice(i, i + size) : null)).filter(e => e);
/* eslint-enable */

const renderWidget = item => (<ContentfulElement key={item.id} {...item} />);

const renderWidgets = (widgets, colClass) => widgets.map(item => (
  <div key={`col-${item.id}`} className={colClass}>
    {renderWidget(item)}
  </div>
));

const renderRows = (props) => {
  const { layout, widgets = [] } = props;
  let size = null;
  let colClass = null;
  switch (layout) {
    case 'SingleCol':
      size = 1;
      colClass = 'col-sm-12';
      break;
    case 'TwoCol':
      size = 2;
      colClass = 'col-sm-6';
      break;
    case 'ThreeCol':
      size = 3;
      colClass = 'col-sm-4';
      break;
    default:
      size = 1;
      colClass = 'col-sm-12';
  }
  const itemArrays = partitionArray(widgets, size);
  return itemArrays.map(itemRow => (
    <div key={`row-${itemRow.id}`} className="row mb20">
      {renderWidgets(itemRow, colClass)}
    </div>
  ));
};

export default (props) => {
  const { title } = props;
  return (
    <section className="section section-boxed mt40">
      <div className="container ">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="title-uppercased large mb20 pull-center br-bottom br-bottom-center">{title}</h3>
          </div>
          {renderRows(props)}
          
        </div>
      </div>
    </section>
  );
};
