/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import CMSWrapper, { ImageEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
//import CMSWrapper, { ImageEditor, TypeEditor } from'../../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';
import TextWidget from './textWidget';
import SectionWidget from './sectionWidget';
import IconTextWidget from './iconTextWidget';
import SectionRow from './SectionRow';
import PageSection from './PageSection';
import TrippleHeaderBlock from './TrippleHeaderBlock';
import BoxedContent from './BoxedContent';
import ImageWidget from './ImageWidget';
import HorizontalBar from './HorizontalBar';
import SimpleCollectionOfEntities from './SimpleCollectionOfEntities';
import ButtonLink from './ButtonLink';
import GenericElement from './genericElement';
import RichText from './RichText';
import TextElement from './textElement';


class ContentfulElement extends Component {
  renderInternal() {
    const { type } = this.props;
    if (!type) {
      return null;
    }
    if (type === 'genericElement') {
      return (
        <GenericElement {...this.props} />
      );
    }
    if (type === 'textWidget') {
      return (
        <TextWidget {...this.props} />
      );
    }
    if (type === 'sectionWidget') {
      return (
        <SectionWidget {...this.props} />
      );
    }
    if (type === 'iconTextWidget') {
      return (
        <IconTextWidget {...this.props} />
      );
    }
    if (type === 'pageSection') {
      return (
        <PageSection {...this.props} />
      );
    }
    if (type === 'sectionRow') {
      return (
        <SectionRow {...this.props} />
      );
    }
    if (type === 'trippleHeaderBlock') {
      return (
        <TrippleHeaderBlock {...this.props} />
      );
    }
    if (type === 'boxedContent') {
      return (
        <BoxedContent {...this.props} />
      );
    }
    if (type === 'imageWidget') {
      return (
        <ImageWidget {...this.props} />
      );
    }
    if (type === 'horizontalBar') {
      return (
        <HorizontalBar {...this.props} />
      );
    }
    if (type === 'simpleCollectionOfEntities') {
      return (
        <SimpleCollectionOfEntities {...this.props} />
      );
    }
    if (type === 'buttonLink') {
      return (
        <ButtonLink {...this.props} />
      );
    }
    if (type.indexOf('richText') > -1) {
      return (
        <RichText {...this.props} />
      );
    }
    if (type === 'textElement') {
      return (
        <TextElement {...this.props} />
      );
    }
    return (
      <p>
        Unknown Element Type:
        {type}
        on element
        {JSON.stringify(this.props)}
      </p>
    );
  }

  render() {
    // if (getParameterByName('showCMSTags')) {
      return (
        <CMSWrapper {...this.props}>
          {this.renderInternal()}
        </CMSWrapper>
      );
    // }
    // return this.renderInternal();
  }
}

export default ContentfulElement
