import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styled from 'react-emotion';
import { Link } from 'gatsby';

import ContentfulElement from '../ich/contentful-element';

const layoutName = "1JiktKjiPZyxSZGAyDUWS8";

const TopBarWrapper = styled.div`
`;

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.handlePlusClick = this.handlePlusClick.bind(this);
    this.state = { style: { top: -1000 } };
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('load', this.handleLoad.bind(this));
      window.addEventListener('resize', this.handleLoad.bind(this));
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('load', this.handleLoad.bind(this));
      window.removeEventListener('resize', this.handleLoad.bind(this));
    }
  }

  handleLoad() {
    if (this.topBarWrapper) {
      const top = this.topBarWrapper.clientHeight * -1;
      this.setState({ style: { top } });
    }
  }

  handlePlusClick() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    const topBarClass = classNames();
    const {content} = this.props;
    const { layouts, annexProduct } = content.data;
    return (
      <div className="transparent_wrapper">
      <header className="page_header header_darkgrey ds page_header_side vertical_menu_header">
        <div className="container-fluid">
          <div className="row flex-wrap v-center">
            <div className="col-xs-12"> <Link to="/" className="logo">
                  <img src={layouts[layoutName].logo.size({
                width: 350,
                format: 'png'
              })} alt="" />
              </Link> <span className="toggle_menu_side header-slide"><span/></span>
              <div className="scrollbar-macosx">
                <div className="side_header_inner">
                  <div className="container-fluid">
                    <div className="row flex-wrap v-center">
                      <div className="col-xs-12 col-sm-6"> <a href="./" className="logo">
                        <img src={layouts[layoutName].logo.size({
                            width: 350,
                            format: 'png'
                          })} alt="" />
                        </a> </div>
                      <div className="col-xs-12 col-sm-6 text-center text-sm-right" />
                    </div>
                  </div>
                  <div className="header-side-menu darklinks">
                    <nav className="mainmenu_side_wrapper">
                      <ul className="nav menu-side-click">
                        <li> <Link to="/">Home</Link></li>
                        {annexProduct.all.map((p,i)=> 
                          <li><Link to={`product/${p.slug}`}>{p.title}</Link></li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  content: state.contentful,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBar);
