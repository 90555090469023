import React from 'react';
import ContentItem from '../index';

export default (props) => {
  const { content = [] } = props;
  return (
    <div>
      {content.map(item => (
        <ContentItem key={item.id} {...item} />
      ))}
    </div>
  );
};
