/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = (props) => {
    const { recaptchaSiteKey } = props;
    return (
  <div>
    {props.meta.touched && props.meta.error}
    <ReCAPTCHA
      sitekey={recaptchaSiteKey}
      onChange={response => props.input.onChange(response)}
    />
    </div>
)};

export default Captcha;