import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import styled, {css} from 'react-emotion';
import MessageTooltip from './FormToolTip';
import FormElementWrapper from "./FormElementWrapper"

const AutosuggestBox = styled(Autosuggest)`
`;

class AutosuggestComponent extends Component { 
  constructor(props) {
    super(props);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);    
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    /* https://github.com/moroshko/react-autosuggest */
    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: []
    };
    this.filterField = props.filterField;
    this.suggestions = props.suggestions;
  }
  
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested ({ value, reason }) {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };
  
  
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  };
  
  getSuggestions(value) {
    if(value) {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
    
      return this.suggestions.filter(x => x[this.filterField].toLowerCase().slice(0, inputLength) === inputValue);
    }
    return this.suggestions;
  };

  getSuggestionValue(value) {
    return value[this.filterField];
  }

  renderSuggestion(suggestion) {
    return (
      <span>{this.getSuggestionValue(suggestion)}</span>
    );
  }

  onSelection(parentFunction) {
    return (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
      parentFunction(suggestion);
    }
  }

  render() {
    const { label = this.props.title, 
    input,
    meta, required,
    onSelection, 
    inputProps} = this.props;

    const passedInputs = {...inputProps,
      ...input,
      type: 'search'
    }
    return (
      <FormElementWrapper type="input" {...this.props}>
        <AutosuggestBox placeholder={label} {...input} required={required} 
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          onSuggestionSelected={this.onSelection(onSelection)}
          renderSuggestion={this.renderSuggestion}
          inputProps={passedInputs}
          renderItem={o => o.name}/>
        <MessageTooltip {...meta} />
      </FormElementWrapper>
    );
  }
}

export default AutosuggestComponent;