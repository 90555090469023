
const data = require('./state.json');


export default class InfrastructureConfig {
  constructor(){
     this.data = data;
  }


  getOutputs() {
    return this.data;//.modules.filter(e => e.path.includes('root'))[0];
    
    /*return {
      region: moduleOutput.outputs.region.value,
      main: moduleOutput.outputs.website.value.main,
      redirect: moduleOutput.outputs.website.value.main
    };*/
  }
}