import React from 'react';
import { Link } from 'gatsby';
import ContentItem from '../index';

export default (props) => {
  const { content } = props;
  return (
    <Link to="#">
      {content.map(item => (
        <ContentItem key={item.id} {...item} />
      ))}
    </Link>
  );
};
