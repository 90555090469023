import styled, {css} from 'react-emotion';

export const Box = css`
  background-color: $color;
`

export const BoxStyle1 = css`
  position: relative;
  text-align: center;

  & .icon {
    position: absolute;
    bottom: 5px;
    right: 20px;
    font-size: 80px;
    color: #dadbde;
    opacity: 0.7;
    z-index: -1;
    -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
  }

  $ :hover .icon {
    color: #d0d2d6;
    opacity: 1;
    -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
  }
`;

export const BoxStyle2 = css`
  
  &:hover .title-small {
    color: rgb(255,139,0);
    -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
  }
  & .icon {
    -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
  }
  &:hover .icon {
    -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
  }
  &.box-services-2a {
    text-align: center;
  }
  &.box-services-2a .icon {
    display: inline-block;
    margin: 0 0 15px;
  }
  & .icon-1 {
    display: block;
    font-size: 30px;
    width: 60px;
    height: 60px;
    color: #fff;
    background-color: #505358;
    border-radius: 50%;
    padding: 15px;
    margin-top: 5px;
    position: relative;
    overflow: hidden;
  }
  & .icon-1:after,
  & .icon-3:after {
    content: "";
    position: absolute;
    top: 25px;
    left: -23px;
    width: 79px;
    height: 48px;
    background-color: rgba(255,255,255,0.1);
    -ms-transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }
  &:hover .icon-1 {
    background-color: rgb(255,139,0);
  }
  & .icon-2 {
    font-size: 55px;
    display: block;
    margin: 5px 0 0 0;
    color: rgb(255,139,0);
  }
  & :hover .icon-2 {
    color: #505358;
  }
  & .icon-3 {
    display: block;
    font-size: 28px;
    width: 60px;
    height: 60px;
    background-color: rgb(255,139,0);
    color: #fff;
    border-radius: 50%;
    margin-top: 5px;
    padding: 15px;
    position: relative;
    overflow: hidden;
  }
  & :hover .icon-3 {
    background-color: #505358;
  }
  & .icon-4 {
    color: #505358;
    display: block;
    margin-top: 5px;
    font-size: 60px;
  }
  & :hover .icon-4 {
    color: rgb(255,139,0);
  }
`;

export const BoxStyle4 = css`
  background-color: rgba(255, 255, 255, .9);
  position: relative;
  color: #505256;
  padding: 40px 30px 40px;
  overflow: hidden;
  height: 100%;
  
  & .title {
    margin-bottom: 18px;
    
    font-family: "Montserrat", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
  }
  &.dark {
    background-color: #35373a;
    color: #d2d2d9;
    & .title, & .title-small {
      color: #d2d2d9;
    }
  }
  &.orange {
    background-color: #d18b21;
  }
  &.blue {
    background-color: #1375a3;
  }
  &.yellow {
    background-color: #fcc813;
  }
  &.green {
    background-color: #22aba6;
  }
  &.transparent {
    background-color: transparent;
    color: #667;
    & .title {
      color: #667
    }
  }
  &.gray {
    background-color: #f0f1f2;
  }
  & .floating.icon {
    font-size: 80px;
    opacity: 0.25;
    position: absolute;
    color: #d2d2d9;
  }

  & .header.icon.left {
    padding-right: 10px;
  }

  & .floating.icon.left {
    left: 10px;
  }
  & .floating.icon.bottom {
    bottom: 10px;
  }
  & .floating.icon.right {
    right: 10px;
  }
  & .floating.icon.top {
    top: 10px;
  }
  & .floating.icon.center {
    left: calc(50% - 40px);
  }

  & .icon.montserrat {
    bottom:25px;
  }
  & .bg-overlay {
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
  }
  & :hover .bg-overlay {
    opacity: 0.4;
    -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
  }
`;

export const BoxStyle7 = css`
& .title-small {
  margin-bottom: 10px;
}
&:hover .title-small {
  color: rgb(255,139,0);
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
& .icon {
  margin-right: 10px;
  font-size: 20px;
  padding-top: 5px;
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
&:hover .icon {
  color: rgb(255,139,0);
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
`
/*

-- Box left and right
.box-left {
  display: block;
  float: left;
  text-align: center;
}
.box-left img {
  width: 60px;
  height: 60px;
  margin-top: 10px;
}
.box-right {
  margin-left: 85px;
}
.box-right .title-small {
  margin-bottom: 8px;
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}





-- Box Services style 3
.box-services-3 {
  position: relative;
  padding: 40px 30px;
  color: #dedee3;
}
.box-services-3:hover .social-icon a {
  margin-left: 0;
}
.box-services-3.box-services-team {
  padding: 60px 40px 30px;
}
.box-services-3 .title {
  font-size: 20px;
  color: #dedee3;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
.box-services-3:hover .title {
  color: rgb(255,139,0);
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
.box-service-3 .title-medium {
  font-size: 22px;
}
.box-services-3 .title-a {
  color: #dedee3;
}
.box-services-3 .bg-overlay {
  opacity: 0.7;
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
.box-services-3:hover .bg-overlay {
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}


-- Box services style 4



-- Box services style 5
.box-services-5 .icon {
  font-size: 50px;
}


-- Box services style 6 
.box-services-6 {
  padding: 25px 20px;
  border: 1px solid #eee;
  border-radius: 2px;
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
.box-services-6.small-padding {
padding:15px 10px;
}
.box-services-6:hover {
  border: 1px solid #e0e3e8;
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
.box-services-6 img {
  margin-bottom: 30px;
}
.box-services-6 p {
  color: #808388;
  margin-bottom: 30px;
}


-- Box Services Style 7
.box-services-7 .title-small {
  margin-bottom: 10px;
}
.box-services-7:hover .title-small {
  color: rgb(255,139,0);
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
.box-services-7 .icon-5 {
  margin-right: 10px;
  font-size: 20px;
  padding-top: 5px;
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
.box-services-7:hover .icon-5 {
  color: rgb(255,139,0);
  -webkit-transition: all 0.3s ease-out; transition: all 0.3s ease-out;
}
*/