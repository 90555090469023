import React from 'react';
import classNames from 'classnames';

export default (props) => {
  const {
    id, title, icon = '', displayMode, body, iconFeatures = [], options = {},
  } = props;
  const iconClassName = classNames('icon', 'fa', `icon_${icon}`, {
    mr10: displayMode === 'IconLeft' || displayMode === 'IconLeftNoBody',
    'color-main': iconFeatures.includes('color-main'),
    'icon-1': !options.secondary,
    'icon-2': options.secondary,
  });
  
  if (displayMode === 'IconLeftNoBody') {
    return (
      <div className="box-services-2">
        <div className="box-left"><i className={iconClassName} /></div>
        <div className="box-right">
          <h3 className="title-small">{title}</h3>
        </div>
      </div>
    );
  } if (displayMode === 'IconLeft') {
    return (
      <div className="box-services-2">
        <div className="box-left"><i className={iconClassName} /></div>
        <div className="box-right">
          <h3 className="title-small">{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: body }} />
          <div className="br-bottom" />
        </div>
      </div>
    );
  } if (displayMode === "rhee-tdk-benedits") {
    const iconLinkClass = classNames([
      "icon",
      "icon-circled",
      "icon-bordered",
      "icon-gray",
      "icon-border-effect",
      "mb-0",
      "mr-10",
      "pull-left",
      "flip"
    ]);
    const iconClass = classNames([
      "flaticon-sport",
      "text-theme-colored"
    ]);

    return (
      <div className="icon-box mb-60 p-0">
        <div className={iconLinkClass}>
          <i className={iconClass} />
        </div>
        <div className="pt-13">
          <h4 className="icon-box-title mt-0 mb-0 text-theme-colored">{title}</h4>
          <p key={`${id}${Math.random()}`} className="text-white" dangerouslySetInnerHTML={{ __html: body}} />
        </div>
      </div>
    );
  } return (
    <p>
Unknown Mode
      {' '}
      {displayMode}
      {' '}
    </p>
  );
};
