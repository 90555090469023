import React from 'react';

import Document from './document';
import Text from './text';
import Paragraph from './paragraph';
import BlockQuote from './blockQuote';
import OrderedList from './orderedList';
import UnorderedList from './unorderedList';
import ListItem from './listItem';
import HyperLink from './hyperLink';
import EntityHyperLink from './entityHyperLink';
import AssetBlock from './assetBlock';
import EntityBlock from './entityBlock';
import AssetLink from './assetLink';

export default (props) => {
  const { type } = props;
  switch (type) {
    case 'richText':
      return (
        <Document {...props} />
      );
    case 'richText-text':
      return (
        <Text {...props} />
      );
    case 'richText-hyperlink':
      return (
        <HyperLink {...props} />
      );
    case 'richText-entry-hyperlink':
      return (
        <EntityHyperLink {...props} />
      );
    case 'richText-embedded-asset-block':
      return (
        <AssetBlock {...props} />
      );
    case 'richText-asset-hyperlink':
      return (
        <AssetLink {...props} />
      );
    case 'richText-embedded-entry-inline':
    case 'richText-embedded-entry-block':
      return (
        <EntityBlock {...props} />
      );
    case 'richText-heading-1':
    case 'richText-heading-2':
    case 'richText-heading-3':
    case 'richText-heading-4':
    case 'richText-heading-5':
    case 'richText-heading-6':
    case 'richText-paragraph':
      return (
        <Paragraph {...props} />
      );
    case 'richText-blockquote':
      return (
        <BlockQuote {...props} />
      );
    case 'richText-ordered-list':
      return (
        <OrderedList {...props} />
      );
    case 'richText-unordered-list':
      return (
        <UnorderedList {...props} />
      );
    case 'richText-list-item':
      return (
        <ListItem {...props} />
      );
    case 'richText-hr':
      return (
        <hr />
      );
    default:
      return (
        <p>
          Unknown Element Type:
          {type}
          &nbsp;on element
          {JSON.stringify(props)}
        </p>
      );
  }
};
