import React from 'react';

export default (props) => {
  const { children } = props;
  return (
    <form className="form ajax-contact-form">
      {children}
    </form>
  );
};
