import React from 'react';
import styled from 'react-emotion';
import FormElementWrapper from "./FormElementWrapper"
import MessageTooltip from './FormToolTip';
import BaseComponent from './BaseComponent';

const Checkbox = styled.input`
  
`

class CheckboxComponent extends BaseComponent { 
  render() {
    const {label = this.props.title, subLabel = this.props.subTitle} = this.props;
    return (

    <FormElementWrapper type="checkbox" {...this.props}>
      <Checkbox {...this.props} {...this.props.input} type="checkbox">
      </Checkbox><i></i> {label}
      <MessageTooltip {...this.props.meta} />
    </FormElementWrapper>
    );
  }
}

export default CheckboxComponent;