import React from 'react';
import classNames from 'classnames';
import styled from 'react-emotion';


const SubTitle = styled.span`
  font-size: 0.8em
`;

const Label = styled.label`
  width: 100%;
`;

const FormElementWrapper = (props) => {
  const {
    children,
    type,
    required,
    iconAppend,
    iconPrepend,
    label,
    title,
    subLabel,
    subTitle,
  } = props;
  return (
    <span>
      {type !== 'checkbox'
        ? (
          <Label className="label">
            {label || title}
            {' '}
            <SubTitle>{subLabel || subTitle}</SubTitle>
          </Label>
        )
        : null }
      <Label className={type}>
        {iconAppend
          ? <i className={classNames('icon-append', iconAppend)} />
          : null }
        {required && type !== 'select'
          ? <i className={classNames('icon-prepend', 'icon-asterisk')} />
          : null }
        {iconPrepend && !required
          ? <i className={classNames('icon-prepend', iconPrepend)} />
          : null }
        {children}
      </Label>
    </span>
  );
};


export default FormElementWrapper;
