import React from 'react';
import styled from 'react-emotion';
import BaseComponent from './BaseComponent';


const Button = styled.button`
  width: 30%;
  display: inline-block;
  background-color: #404449;
  font-family: "Montserrat", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
  border-radius: 3px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1.5px;
  padding: 14px 26px;
`;

const ButtonComponent = (props) => {
  const { children, input } = props;
  return (
    <Button {...props} {...input}>
      {children}
    </Button>

  );
};

export default ButtonComponent;
