import ReactGA from 'react-ga'

ReactGA.initialize(global.GA_UI || 'Undefined');

const GAEventAction = 'GA_EVENT'
const GAPageLoad = 'GA_PAGE_LOAD'

export default GU_UI => {
  global.GU_UI = GU_UI;
}

export const GAEvent = event => ({
  type: GAEventAction,
  event,
})

export const GAPageView = event => ({
  type: GAPageLoad,
  event,
})

export const reducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GAPageLoad:
      if (typeof window !== 'undefined') {
        /* eslint-disable */
        ReactGA.pageview(window.location.pathname + window.location.search)
        /* eslint-enable */
      }
      return { ...state }
    case GAEventAction:
      ReactGA.event(payload)
      return { ...state }
    default:
      return state
  }
}
