/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React from 'react';
import classNames from 'classnames';
import { cx } from 'react-emotion';
import ContentfulElement from '.';

import {
  BoxStyle1,
  BoxStyle2,
  // BoxStyle3,
  BoxStyle4,
  // BoxStyle5,
  // BoxStyle6,
  BoxStyle7,
} from './styles/BoxedContent';

const BoxesContent = (props) => {
  const {
    id, type, style, title, body, icon, backgroundColor, maxHeight, iconPosition, subItems = [], options = {},
  } = props;
  const wrapperClass = classNames(options.classNames, { el_max_height: maxHeight });
  const iconClassName = classNames('icon', `icon_${icon}`, {
    floating: iconPosition !== 'Left Header',
    header: iconPosition === 'Left Header',
    bottom: !iconPosition || (iconPosition === 'Bottom Left' || iconPosition === 'Bottom Center' || iconPosition === 'Bottom Right'),
    right: !iconPosition || (iconPosition === 'Top Right' || iconPosition === 'Bottom Right'),
    left: iconPosition === 'Top Left' || iconPosition === 'Bottom Left' || iconPosition === 'Left Header',
    top: iconPosition === 'Top Left' || iconPosition === 'Top Center' || iconPosition === 'Top Right',
    center: iconPosition === 'Bottom Center' || iconPosition === 'Top Center',
  });
  const boxClass = cx((options && options.box && options.box.classNames), backgroundColor.toLowerCase(), {
    [BoxStyle1]: style === '1',
    [BoxStyle2]: style === '2',
    // [BoxStyle3]: style == "3",
    [BoxStyle4]: !style || style === '4',
    // [BoxStyle5]: style == "5",
    // [BoxStyle6]: style == "6",
    [BoxStyle7]: style === '7',
  });

  const titleClass = classNames(
    'title', 'title-small',
    {
      'title-a': options.bar === 'LeftHeader',
      'title-upperCase': options && options.title && options.title.uppercase,
    },
    options && options.title && options.title.classNames,
  );
  return (
    <div className={wrapperClass}>
      <div className={boxClass}>
        {' '}
        {/* backgroundColor={backgroundColor} iconPosition={iconPosition} */}
        { options.bar === 'Top' ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
        { options.overlay ? <div className="bg-overlay" /> : null }
        <h3 className={titleClass}>
          {iconPosition && iconPosition === 'Left Header' ? <i className={iconClassName} /> : null }
          {' '}
          {title}
        </h3>
        { options.bar === 'Middle' ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
        <p className="mb0" dangerouslySetInnerHTML={{ __html: body }} />
        { options.bar === 'Bottom' ? <div className="br-bottom mb20 hidden-sm hidden-xs" /> : null }
        {iconPosition && iconPosition !== 'None' && iconPosition !== 'Left Header' ? <i className={iconClassName} /> : null }
        {subItems && subItems.map(item => <ContentfulElement key={item.id} {...item} noWrapper />)}
      </div>
    </div>
  );

  /* return ( <p>Unknown Mode</p> ); */
};

export default BoxesContent;

/*
<div className="col-sm-12 col-md-4 el_max_height">
  <div className="box-services-4 transparent sm-pt0">
      <Row className="col-p0">
          <div className="col-sm-12">
              <div className="br-bottom mb20 hidden-sm hidden-xs" />
              <h3 className="title title-uppercased small color-default">Thorugh and easy to understand</h3>
              <p className="mb0 color-default">Hunter Valley pest and Building Inspection only performs detailed thorough reports that include colour photographs; the reports are in laymen’s terms, so you can easily understand the written reports which is very important.</p>
          </div>
      </Row>
  </div>
</div>
*/
