import React from 'react';
import styled from 'react-emotion';

const DivToolTip = styled.div`
  # position: relative;
  display: ${props => (!props.active && props.touched && props.error ? 'block' : 'none')};
  # background-color: ${props => props.theme.palette.secondary.error};
  # margin-top: 28px;
  border: ${props => props.theme.palette.secondary.error} 1px solid;
  color: ${props => props.theme.palette.primary.error};
  #border-radius: .4em;
  padding: 2px 20px;
}
`;

export default (props) => {
  const { touched, error, warning } = props;
  return (
    <DivToolTip {...props}>
      {touched
        && ((error && <span>{error}</span>)
          || (warning && <span>{warning}</span>))}
    </DivToolTip>
  );
};
