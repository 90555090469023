import React  from 'react';
import styled from 'react-emotion';
import FormElementWrapper from "./FormElementWrapper"
import MessageTooltip from './FormToolTip';
import BaseComponent from './BaseComponent';


const Select = styled.select`
`

class SelectBox extends BaseComponent { 
  render() {
    const {options} = this.props;
    return (
      <FormElementWrapper type="select" {...this.props} >
          <Select {...this.props} {...this.props.input}>
            {this.props.children}
          </Select>
      </FormElementWrapper>
    );
  }
}

export default SelectBox;