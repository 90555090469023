import React from 'react';
import { Link } from 'gatsby';
import ContentItem from '../index';

export default (props) => {
  const { content, asset } = props;
  return (
    <Link to={`\\${asset.src}`}>
      {content.map(item => (
        <ContentItem key={item.id} {...item} />
      ))}
    </Link>
  );
};
