import React from 'react';
import styled from 'react-emotion';
import ContentItem from '../index';

const UnorderedList = styled.ul`
`;

export default (props) => {
  const { content = [] } = props;
  return (
    <UnorderedList>
      {content.map(item => (
        <ContentItem key={item.id} {...item} />
      ))}
    </UnorderedList>
  );
};
